$fonthead: 'Rubik', sans-serif;
$fontstack: 'Rubik', sans-serif;
$colordark: #31755e;
$coloryellow: #e4ca8aca;
$colorpink: #c33646;
$colorblue: #6ca5e5;
$colordefault: #454360;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}